<template>
    <cron-component></cron-component>
</template>

<script>
import cronComponent from '../components/cron/cronComponent.vue'

export default {
  components: { cronComponent },

}
</script>

<style>

</style>
