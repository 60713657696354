<template>
  <div>
    <div v-if="!loading">
      <v-fade-transition mode="in-out" hide-on-leave>
        <cron-data v-if="!loading" :monitorData="monitorData" :seconds="seconds"></cron-data>
      </v-fade-transition>
    </div>
    <v-fade-transition mode="in-out" hide-on-leave>
      <div v-if="loading">
        <div class="wrapper">
          <v-sheet color="white" class="h-100 w-100 d-flex align-center justify-center">
            <v-progress-circular indeterminate :size="30" :width="3" color="success " />
          </v-sheet>
        </div>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import cronData from "./cronData.vue";
import EventBus from "@/events/EventBus";

export default {
  components: { cronData},
  name: "cronComponent",
  props: {},
  mixins: [EventBus],
  data: () => ({
    loading: false,
    monitorData: [],
    seconds: 60,
    monitorFetchInterval: null,
    isRunning: false,
  }),
  mounted() {
  },
  methods: {
  },
  computed: {
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
